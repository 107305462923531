:root {
  --primary-100: #0077B6;
  --primary-200: #56a6e9;
  --primary-300: #c3ffff;
  --accent-100: #00A8E8;
  --accent-200: #004d85;
  --text-100: #333333;
  --text-200: #5c5c5c;
  --bg-100: #F0F0F0;
  --bg-200: #e6e6e6;
  --bg-300: #bebebe;
  --white: #ffffff;
  --black: #000000;
}

[data-theme="dark"] {
  --primary-100: #2B5C84;
  --primary-200: #5d89b4;
  --primary-300: #c0ebff;
  --accent-100: #4A90E2;
  --accent-200: #003a80;
  --text-100: #FFFFFF;
  --text-200: #e0e0e0;
  --bg-100: #0F1C2D;
  --bg-200: #1f2b3d;
  --bg-300: #374356;
}

body {
  background-color: var(--bg-100);
  color: var(--text-100);
  transition: background-color 0.3s, color 0.3s;
}

.beta-signup {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-image: url('./assets/landingback.jpg'); /* Adjust path if necessary */
  background-size: cover;
  background-position: center;
}

.beta-signup-box {
  background-color: var(--bg-200);
  border-radius: 8px;
  padding: 16px;
}

.AppBar {
  background-color: var(--primary-100);
  color: var(--text-100);
}

.Container {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.Button {
  margin-top: 16px;
  margin-bottom: 16px;
}

.Box {
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.Card {
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Stepper {
  margin-top: 16px;
  margin-bottom: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--primary-100);
  color: var(--white);
}

.header h1 {
  margin: 0;
  font-size: 1.5rem;
}

.nav {
  display: flex;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav-links.open {
  display: block;
}

.nav-button, .nav-link, .logout-button, .btn {
  background: none;
  border: none;
  color: var(--white);
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
}

.nav-button:hover, .nav-link:hover, .logout-button:hover, .btn:hover {
  color: var(--accent-100);
}

.hamburger-menu {
  display: none;
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .nav-links {
    display: none;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    background-color: var(--primary-100);
    padding: 1rem;
  }
  
  .nav-links.open {
    display: flex;
  }
}

.scatter-transition-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.scatter-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.scatter-transition-exit {
  opacity: 1;
  transform: translateX(0);
}
.scatter-transition-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}
