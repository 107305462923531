/* src/styles/OrganizationOptions.css and src/styles/CreateOrganization.css */
.organization-options-container, .create-organization-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 8px;
    background-color: var(--bg-100);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-100);
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--bg-300);
    border-radius: 4px;
  }
  
  .btn {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--primary-100);
    color: var(--white);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  .btn:hover {
    background-color: var(--primary-200);
  }
  
  .btn-secondary {
    background-color: var(--accent-100);
  }
  
  .btn-secondary:hover {
    background-color: var(--accent-200);
  }
  
  .error {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
  }
  