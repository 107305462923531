.hero-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Adjusted height */
  }
  
  .hero-inner-container {
    display: flex;
    max-width: 1280px;
    width: 100%;
  }
  
  .hero-content {
    flex: 1; /* Take up 50% of the width */
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #F0F0F0;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--primary-100);
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .hero-input-container {
    display: table;
    align-items: center;
    gap: 10px; /* Add space between input and button */
  }
  
  .hero-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid var(--primary-100);
    border-radius: 4px;
    width: 50%; /* Set width to 50% */
    box-sizing: border-box; /* Include padding and border in the element's total width */
  }
  
  .btn-submit {
    background-color: var(--primary-100); /* Using CSS variable */
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border: 1px solid var(--primary-100);
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .btn-submit:hover {
    background-color: var(--primary-200); /* Using CSS variable */
  }
  
  .hero-bg-image {
    flex: 1; /* Take up 50% of the width */
    height: 50vh; /* Ensure the image takes up the height of the container */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  