/* src/styles/ConsequenceList.css */
.consequence-list {
    list-style-type: none;
    padding: 0;
  }
  
  .consequence-item {
    margin: 1rem 0;
    padding: 1rem;
    background-color: white;
    border-radius: 14px;
  }
  
  .consequence-item h3,
  .consequence-item p {
    margin: 0.5rem 0;
    color: var(--text-200);
  }
  
  .add-consequence-form .form-group{
    margin-bottom: 1rem;
  }
  
  .add-consequence-form .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-100);
  }
  
  .add-consequence-form .form-group input,
  .add-consequence-form .form-group textarea,
  .add-consequence-form .form-group select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--bg-300);
    border-radius: 4px;
  }
  
  .btn {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--primary-100);
    color: var(--white);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  .btn:hover {
    background-color: var(--primary-200);
  }
  
  .btn-secondary {
    background-color: var(--accent-100);
  }
  
  .btn-secondary:hover {
    background-color: var(--accent-200);
  }
  
  .message {
    margin-top: 1rem;
    text-align: center;
    color: var(--accent-100);
  }
  