/* src/styles/CreateRiskAssessment.css */
.create-risk-assessment-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--bg-100);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .create-risk-assessment-container h1,
  .create-risk-assessment-container h2,
  .create-risk-assessment-container h3 {
    text-align: center;
    color: var(--primary-100);
  }
  
  .risk-assessment-form .form-group {
    margin-bottom: 1rem;
  }
  
  .risk-assessment-form .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-100);
  }
  
  .risk-assessment-form .form-group input,
  .risk-assessment-form .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--bg-300);
    border-radius: 4px;
  }
  
  .risk-assessment-form .btn-primary {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--primary-100);
    color: var(--white);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .risk-assessment-form .btn-primary:hover {
    background-color: var(--primary-200);
  }
  
  .message {
    margin-top: 1rem;
    text-align: center;
    color: var(--accent-100);
  }
  